// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';
@import 'azm.less';

// Fonts
@import '../font-awesome/less/font-awesome.less';
@import 'iconfont.less';

//Mixins
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

html, body {
  overflow-x: hidden;
}

body {
  overflow-y: hidden;
  &.navbar-is-fixed-top {
    padding-top: @navbar-height;
  }
  &.admin-menu {
    &.navbar-is-fixed-top {
      padding-top: @navbar-height;
    }
  }
  &.modal-open {
    padding-right: 0 !important;
  }
}

h1 {
  @media(max-width: @screen-sm-max) {
    font-size: 30px;
  }
  /* @media(min-width: @screen-md-min) and (max-width: @screen-md-max) {
    font-size: 59px;
  } */
}
h2 {
  @media(max-width: @screen-sm-max) {
    font-size: 30px;
  }
}

h5 {
  text-transform: uppercase;
}

.btn-info {
  &:hover, &:focus {
    border-color: #10a7e9;
    background-color: #10a7e9;
  }
}


.field-type-text-long {
  ul {
    list-style: none;
    position: relative;
    li {
      font-size: 20px;
      line-height: 1.3;
      position: relative;
      margin-bottom: 15px;
      &:before {
        content: "\25CF";
        color: @brand-primary;
        position: absolute;
        left: -30px;
      }
    }
  }
}



.logo {
  margin-left: 15px;
  @media (min-width: @screen-sm-min){
    margin-left: 45px;
  }
}

.page-header {
  color: #e6e6e6;
  margin-top: 100px;
  border: 0 none;
  padding-left: 15px;
  @media (min-width: @screen-sm-min){
    padding-left: 45px;
  }
  em {
    font-style: normal;
    color: #dbf2fc;
  }
}

.region-sidebar-second {
  .block-title {
    .h4;
  }
}

strong {
  font-weight: 600;
}

.view-frontpage-slide {
  .header-bg {
    background-position: center;
    height: ~"calc(100vh - "@navbar-height~")";
    .cover();
  }
  .node-frontpage-slide {
    position:relative;
    .button {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .caption{
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
      color: #fff;
      @media (min-width: @screen-sm-min){
        display: flex;
        align-items: center;
      }
      h1, h2 {
        margin: 0;
      }
      .field-name-field-top-layer {
        @media (max-width: @screen-xs-max){
          img {max-width: 70%;}
        }
      }
    }
  }
}

.navbar {
    transition-property: transform;
    transition-duration: .6s;
}

.navbar-scroll {
    transform: translateY(-76px);
    transition-property: transform;
    transition-duration: .6s;
}

.navbar-default {
  background-color: #fff;
}

.navbar-toggle {
  transition-property: transform;
  transition-duration: .6s;
  border-color: @navbar-default-toggle-border-color;
  &:hover,
  &:focus {
    background-color: @navbar-default-toggle-hover-bg;
  }
  position: fixed;
  top:0px;
  right:0;
  z-index: 1240;
  .icon-bar {
    width: 32px;
    height: 5px;
    transition: all 0.2s;
    background-color: #000;
  }
}
.navbar-toggle[aria-expanded="true"]{
  .icon-bar {
    background-color: #fff;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 50%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 50%;
  }
}
/* Right expanding navbar */

.collapsing.width  {
    //width: 0;
    width: 100%;
    height: auto;
    //.transition(width 0s ease-in-out);
    white-space:nowrap;
}

div.navbar-collapse.width.collapse,
div.navbar-collapse.width.collapse.in,
div.navbar-collapse.width.collapsing,
div.mobile-collapse.width.collapse,
div.mobile-collapse.width.collapse.in,
div.mobile-collapse.width.collapsing {
  width: 100% !important;
}
div.drawer-collapse.width,
div.drawer-collapse.width.collapse,
div.drawer-collapse.width.collapse.in,
div.drawer-collapse.width.collapsing,
{
  width: 100% !important;
}


.adminimal-menu {
  #navright {
    top: 29px; //~"calc(45px + "@navbar-height~")";
  }
  .navbar-toggle {
    top:30px;
  }
}

#navright {
  padding-left: 45px;
  display: block;
  position:fixed;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  top: 0; // ~"calc(18px + "@navbar-height~")";
  z-index: 1030;
  color: #fff;
  width: 100%;
  right: -100%;
  @media (min-width: @screen-sm-min){
    width: 580px;
    right: -580px;
  }
  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s;
  &.expanded {
      right: 0;
  }
  .collapse {
    display:block;
  }
  .close-btn {
    position:relative;
    background-color: transparent;
    font-size: 42px;
    line-height: 1;
    &:hover {
      color: #fff;
    }
    &:focus {
      outline:none;
    }
  }
  .block-title {
    font-size: 18px;
    color: #575f63;
    text-transform: uppercase;
  }

  #navbar-collapse {
    padding-top: 80px;
    padding-bottom: 100px;
  }
  #block-locale-language .block-title {
    &:after {
      content:":";
    }
  }
  .language-switcher-locale-url {
    list-style:none;
    padding: 0;
    > li {
      > a {
        font-size: 26px;
        color: @navbar-default-color;
        text-decoration: none;
        padding-top:    10px;
        padding-bottom: 10px;
        line-height: @line-height-computed;
        display: block;
        &:hover, &:focus {
          color: @navbar-default-link-hover-color;
        }
        &.active {
          color: @navbar-default-link-active-color;
        }
      }
    }
  }

  background-color: @navbar-default-bg;
  border-color: @navbar-default-border;

  .navbar-brand {
    color: @navbar-default-brand-color;
    &:hover,
    &:focus {
      color: @navbar-default-brand-hover-color;
      background-color: @navbar-default-brand-hover-bg;
    }
  }

  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-default-link-color;

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: transparent;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    font-size: 26px;
    .nolink{
      font-size: 18px;
      color: #575f63;
      text-transform: uppercase;
      padding: 0 15px;
      display: block;
      margin-top: 30px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-active-bg;
        color: @navbar-default-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: transparent;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-active-color;
            background-color: transparent;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: transparent;
          }
        }
      }
    }


    // Links in navbars
    //
    // Add a class to ensure links outside the navbar nav are colored correctly.

    .navbar-link {
      color: @navbar-default-link-color;
      &:hover {
        color: @navbar-default-link-hover-color;
      }
    }

    .btn-link {
      color: @navbar-default-link-color;
      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
      }
      &[disabled],
      fieldset[disabled] & {
        &:hover,
        &:focus {
          color: @navbar-default-link-disabled-color;
        }
      }
    }
  }
}

#block-views-frontpage-blocks-block {
  .view-frontpage-blocks {
    div[class^='col-'], div[class*=' col-'] {
      padding: 0;
    }
    .node {
      position:relative;
	  color: @gray-darker;
	  &.white-text {
		color: #fff;	  
	  }
      .teaser-bg {
        .cover();
        background-position: center;
        height: 540px;
      }
      .group-text {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 30px;
        @media (min-width: @screen-sm-min){
          padding: 60px;
        }
        color: inherit;
        backround-color: transparent;
        -webkit-transition: backround-color .5s;
          transition: backround-color .5s;
        a {
          color: inherit;
          text-decoration: none;
        }
        .field-name-body {
          opacity:0;
          color: inherit;
          -webkit-transition: opacity .5s;
          transition: opacity .5s;
        }
        .field-name-field-link {
          opacity:0;
          -webkit-transition: opacity .5s;
          transition: opacity .5s;
          margin-top: 10%;
          .field-item {
            margin-bottom: 10px;
            a {
              line-height: 40px;
              position: relative;
              font-size: 20px;
              font-weight: 600;
              color: iherit;
              text-decoration:none;
              &:after {
                background: #fff;
                bottom: -5px;
                content: "";
                left: 0;
                height: 5px;
                width: 50%;
                position: absolute;
                right: 0;
                transition: 0.2s width;
              }
              &:hover {
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }
        &:hover {
		  color: #fff;
          background-color: rgba(6,23,32,.84);
		  a { color: #fff; }
          .field-name-body {
            opacity:1;
			color: #fff;
          }
          .field-name-field-link {
            opacity:1;
			.field-item {
				a { color: #fff; }
			}
          }
        }
      }
    }
  }
}

#block-views-case-and-concept-block-1 {
  .view-case-and-concept {
    .views-bootstrap-grid-plugin-style {
      > .row {
        @media (min-width: @screen-lg-min){
          display:flex;
          align-items: center;
        }
      }
    }
    .no-padding {
      padding: 0;
    }
    .node {
      &.node-custom-text {
        padding: 60px 0 60px 30px;
        @media (min-width: @screen-sm-min){
          padding: 60px 0 60px 60px;
        }
        @media (min-width: @screen-lg-min){
          padding: 30px 0 30px 60px;
        }
        h2 {
          margin-top: 0;
        }
      }
      .field-name-field-link {
        margin-top: 30px;
        .field-item {
            margin-bottom: 10px;
            a {
              line-height: 40px;
              position: relative;
              font-size: 20px;
              font-weight: 600;
              color: #242424;
              text-decoration:none;
              &:after {
                background: #242424;
                bottom: -5px;
                content: "";
                left: 0;
                height: 5px;
                width: 50%;
                position: absolute;
                right: 0;
                transition: 0.2s width;
              }
              &:hover {
                &:after {
                  width: 100%;
                }
              }
            }
        }
      }

      .group-link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin:0 auto;
        color: #fff;
        text-decoration: none;
        background-color: #000000;
        opacity: 0;
        webkit-transition: opacity .5s;
          transition: opacity .5s;

        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        &:hover {
          opacity: 0.82;
        }
        .field-name-title-field {
          color: #fff;
          text-decoration: none;
          text-align:center;
        }
      }
    }
  }
}

.view-references {
  .node {
    margin-bottom: 30px;
    position: relative;
    .group-link {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin:0 auto;
      color: #fff;
      text-decoration: none;
      background-color: #000000;
      opacity: 0;
      webkit-transition: opacity .5s;
        transition: opacity .5s;

      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;

      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center;

      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      &:hover {
        opacity: 0.82;
      }
      .field-name-title-field {
        color: #fff;
        text-decoration: none;
        text-align:center;
      }
    }
  }
}


/*#builder-row-2-3 {
 .row();
 background-color: #f5f8f9;
 padding-top: 100px;
 padding-bottom: 60px;
}*/

.footer {
  margin-top: 0;
  .block-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  #block-bean-contact-us {
    @media (min-width: @screen-sm-min){
      padding-left: 45px;
    }
    .block-title {
      text-transform: none;
      font-size: 53px;
      margin-bottom: 0;
    }
    h4 {
      margin-top: 0;
    }
  }
  .field.field-name-field-social-links {
    .field-label {
      font-weight: normal;
      margin-bottom: 30px;
    }
    .azm-size-32 {
      font-size: 25px;
      line-height: 32px;
      padding:0;
      margin: 0 10px 10px 0;
    }
    .field-item {
      display: inline-block;
    }
    i.fa.fa-facebook {
      position: relative;
      top: 0px;
      left: 7px;
      font-size: 25px;
    }
  }
  .bean-link-block {
    a {
      line-height: 40px;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #242424;
      text-decoration:none;
      &:after {
        background: #242424;
        bottom: -5px;
        content: "";
        left: 0;
        height: 5px;
        width: 50%;
        position: absolute;
        right: 0;
        transition: 0.2s width;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
    .field-item {
      margin-bottom: 10px;
    }
  }
  .field-name-field-copyright {
    margin-top: 50px;
  }
}

/* Share this */
.sharethis-buttons span {
  color: #c6c6c6;
  border: 1px solid;
  border-color: #e7e7e7;
  display: inline-block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  &:hover {
    border-color: #c2c2c2;
  }
}
.st_sharethis_custom {
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: @fa-var-share-alt;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_twitter_custom {
  &:hover {
    cursor: pointer;
    color: #00cdeb;
  }
  &:before {
    content: @fa-var-twitter;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_facebook_custom {
  &:hover {
    cursor: pointer;
    color: #3B5998;
  }
  &:before {
    content: @fa-var-facebook;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_googleplus_custom {
  &:hover {
    cursor: pointer;
    color: #dd4b39;
  }
  &:before {
    content: @fa-var-google-plus;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_pinterest_custom {
  &:hover {
    cursor: pointer;
    color: #C92228;
  }
  &:before {
    content: @fa-var-pinterest-p;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}

.node-blog {
  .sharethis-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: right;
  }
}

#block-views-blog-block-2 {
  .view-blog {
    .views-row {
      padding: 15px;
      &:hover {
        background-color: #f7f7f7;
      }
      .field.field-name-field-image {
        max-width: 80px;
        margin-right: 15px;
      }
      .field.field-name-title-field {
        font-weight:400;
        margin-bottom: 12px;
        a {

        }
      }
      .field-name-author,
      .field-name-user {
        text-transform: uppercase;
      }
      .field-name-post-date {
        float: none !important;
      }
      .label-inline {
        margin-right: 5px;
      }
    }
  }
}

#block-views-blog-block-3 {
  .block-title {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
  }
  .view-blog {
    > .view-content .row {
      margin-bottom: 10px;
    }
    > .view-content .col {
      > .node {
        position: relative;
        max-width: 255px;
        &:hover {
          cursor:pointer;
        }
      }
      .field {
        &.field-name-post-date {
          padding: 10px 20px;
          color: #969595;
          position: absolute;
          width: 100%;
          bottom: 0;
          background-color: #4a4a4a;
        }
      }
    }
    .more-link {
      a {
        color: #969595;
        &:after {
          content: "\00BB";
          color: #31aae2;
        }
      }
    }
  }
}


#block-views-blog-block-4 {
  .view-blog {
    .views-bootstrap-grid-plugin-style {
      > .row > div:first-child {
        @media (min-width: @screen-sm-min) {
          padding-left: 60px;
        }
        @media (min-width: @screen-md-min) {
          padding-left: 15px;
        }
      }
    }
    .view-header {
    }
    .view-footer {
      text-align: center;
      margin-top: 50px;
      .btn.btn-default {
        background-color: transparent;
        border-color: #dcdcdc;
        color: #c1c1c1;
        font-weight: 600;
        &:hover {
          border-color: #5d5d5d;
          color: #5d5d5d;
        }
      }
    }
    .view-content {
      .node {
        @media (max-width: @screen-lg-min) {
          margin-bottom: 30px;
          @media (max-width: @screen-xs-max){
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .media-left, .media-right {
          @media (max-width: @screen-sm-max){
            display: block;
            padding-right: 0;
            padding-left: 15px;
          }
        }
        .media-right {
          @media (min-width: @screen-sm-min){
            padding-right: 20px;
          }
        }
        .field-name-post-date {
          background-color: @text-color;
          color: #fff;
          padding: 5px 15px;
          display: inline-block;
          //white-space:nowrap;
          font-size: 17px;
          margin-bottom: 15px;
        }
        .group-wrapper {
          position:relative;
          /*max-width: 720px;*/
          .group-counters {
            padding: 10px;
            color: #fff;
            width: 100%;
            background-color: #1e1e1e;
            position: absolute;
            bottom: 0;
            .field, .flag-outer {
              a {
                color: #fff;
              }
              .pull-right;
              color: #fff;
              margin-left: 10px;
              i {

              }
              .flag {
                span {
                  font-size: 0px;
                  visibility: hidden;
                }
                color: #fff;
                text-decoration: none;
              }
              &.field-name-post-date {
                background-color: @text-color;
                color: #fff;
                padding: 5px 15px;
              }
            }
          }
        }
      }
    }
  }
}
.view-case-and-concept,
.view-blog, .node-blog {
  .flag-outer {
     margin-left: 0px;
    .pull-left();
    .flag {
      color: inherit;
      text-decoration: none;
      span {
        visibility: hidden;
        display: none;
      }
      i {

      }
    }
  }
  .field {
    &.field-name-post-date {

    }
    &.field-name-comments-count {
      .pull-left();
      margin-left: 10px;
    }
    &.field-name-comments-count .field-item a {
      display: inline-block;
      text-decoration: none;
      &:before {
        content: @fa-var-comment-o;
        display: inline-block;
        margin-right: 2px;
        font: normal normal normal 14px/1 FontAwesome;
        color: inehrit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
      }
    }
    &.field-name-title-field {
      a {
        text-decoration: none;
        &:hover {

        }
      }
      h1 {
        font-weight: 400;
      }
    }
    &.field-name-body {
      clear: both;
    }
    &.field-name-field-flickr {
      margin-top: 30px;
    }
  }
}
.group-date-counter {
  padding-bottom: 30px;
  .field {
    margin-right: 5px;
    display: inline-block;
  }
}
.node-blog {
  .view-user {
    margin-top: 30px;
    .views-field.views-field-field-employee{
       > .field-content {margin-top: 15px; }
    }
    .field-name-field-pool-employee-image {
      display: inline-block;
      margin-right: 15px;
      img {
        border-radius: 50%;
        border: 5px solid #d5d7d6;
      }
    }
    .group-wrapper {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.view-blog,
.view-case-and-concept {
  .view-content {
    .view-mode-large_teaser,
    .view-mode-teaser {
      .field {
        &.field-name-title-field {
          h4 {
            margin-top: 0;
            font-weight:600;
          }
        }
        &.field-name-node-link {
          margin-top: 25px;
          font-weight:600;
          a {
            color: #2e2e2e;
            text-decoration: none;
            &:after {
              content: @fa-var-long-arrow-right;
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size:20px;
              color: inehrit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              transform: translate(0, 0);
              margin-left: 10px;
            }
            &:hover {
              color: #8f9294;
              &:after {
                border-color: #8f9294;
              }
            }
          }
        }
        &.field-name-post-date {
        }
      }
    }
    .view-mode-teaser {
      .field {
        &.field-name-post-date {
        }
      }
    }
  }
  &.view-display-id-page{
    .views-row {
      border-bottom: 1px solid #e8e8e8;
      padding-top: 40px;
      padding-bottom: 50px;
      &.views-row-last {
        border-bottom: none;
      }
    }
  }
}

/* Comments */
#comments {
  .title {
    margin-bottom: 30px;
  }
}
.comment-form {
  .grippie {
    display: none;
  }
  label {
    position: absolute;
    text-indent: -9999px;
  }
  .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  @media (min-width: @screen-sm-min) {
    > div {
      display: table;
      width: 100%;
    }
    .form-type-textfield {
      .pull-left();
      width: 49%;
      &:first-of-type {
        margin-right: 2%;
      }
    }
    .form-type-textarea {
      clear: both;
    }
  }
  .btn {
    .pull-right;
    &.btn-primary {
      background-color: transparent;
      border-color: #dcdcdc;
      color: #c1c1c1;
      font-weight: 600;
      &:hover {
        border-color: #5d5d5d;
        color: #5d5d5d;
      }
    }
  }
  .form-control {
    border-color: #f1f1f1;
  }
}
.title.comment-form {
  font-size: 20px;
}
#comments {
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #313131;
  }
}
.comment {
    padding: 40px 30px;
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
  .username {
    color: #31aae2;
    font-style: normal;
  }
  .created {
    font-style: normal;
  }
  .links.list-inline {
    text-align: right;
    > li > a {
      border: 1px solid #f1f1f1;
      padding: 10px 15px;
      text-decoration: none;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}


blockquote {
  border: none;
  padding-left: 100px;
  padding-right: 100px;
  &:before {
    content: "\201C";
    font-size: 80px;
    line-height: 1;
    position: absolute;
    left: 70px;
    font-family: Times;
    color: #03a9f4;
    font-style: italic;
  }
}


.node-type-blog, .page-blog {
  .main-container {
    .container();
  }
}

/* Flickr */

#block-flickr-11 {
  .flickr-album-heading {
    display: none;
  }
  .flickr-credit {
    display: none;
  }
  .flickr-photoset {
    margin: 15px;
  }
}


.bg-grey {
  background-color: #f6f5f8;
}

/* Paragraphs */

.paragraphs-items {
  .field-name-field-page-contents {
    > .field-items {
     > .field-item {
        //.row();
        &:first-child {
          > .entity-paragraphs-item {
            &.paragraphs-item-full-width-banner {
              // padding-bottom: 80px;
            }
          }
        }
        &:nth-child(2){
          > .entity-paragraphs-item {
            &.paragraphs-item-left-right-text {
              padding-top: 0;
            }
          }
        }
        > .entity-paragraphs-item {
           &.paragraphs-item-text-and-button.timle {
             background-color: #3E296E;
             .btn-info {
                background-color: lighten(#3E296E, 10%);
                border-color: lighten(#3E296E, 10%);
                &:hover, &:focus {
                  background-color: lighten(#3E296E, 12%);
                  border-color: lighten(#3E296E, 12%);
                }
             }
		  }
        }
        > .entity-paragraphs-item {
          &.paragraphs-item-full-width-banner {
            .row();
          }
          &.paragraphs-item-left-right-text {
            padding-top: 80px;
            padding-bottom: 80px;
          }
          &.paragraphs-item-image-and-text {
            .row();
            padding-top: 30px;
            padding-bottom: 30px;
            > .container  {
              padding: 15px 30px;
              @media (min-width: @screen-sm-min){
                padding: 15px 15px;
              }
            }
          }
          &.paragraphs-item-text-and-button,
          &.paragraphs-item-text-and-modal-form {
            .row();
            padding-top: 60px;
            padding-bottom: 75px;
            background-color: @brand-primary;
            .field-name-field-bundle-text {
              color: #fff;
            }
            .field-name-field-bundle-link {
              text-align: center;
              margin: 15px 0;
            }
            .modal-header {
              .close {
                color: #000;
              }
            }
            > .container > .row {
              @media (min-width:@screen-sm-min){
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          > .container > .row,
          &.container > .row {
            @media (min-width:@screen-sm-min){
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            @media (min-width:@screen-lg-min){
              > div.col-sm-6 {
                > div {
                  .make-lg-column(11);
                  padding: 0;
                }
                &:nth-child(2) {
                  > div {
                    /* .make-lg-column-offset(1); */
                  }
                }
              }
            }
          }
          &.paragraphs-item-slideshow {
            .row();
            .carousel.slide {
              > .carousel-inner {
                > .item {
                  > div {
                    padding-bottom: 37.24%;
                    min-height: 400px;
                  }
                }
              }
            }
          }
          &.paragraphs-item-full-width-banner {
              .background-img {
                background-position: center center;
                .cover();
                padding-bottom: 27.08%;
                min-height: 200px;
              }
          }
          &.paragraphs-item-full-width-text {
            padding-left: 15px;
            @media(min-width: @screen-sm-min){
              padding-left: 45px;
            }
          }
        }
      }
    }
  }
}

.page-node-1490 {
  .field-type-paragraphs {
    > .field-items {
      > .field-item {
        &:nth-child(4) {
          .row();
          background-color: #f6f5f8;
        }
        &:nth-child(5) {
          > .entity-paragraphs-item.paragraphs-item-text-and-button {
            background-color: #f0f0f0;
            .btn-info {
              background-color: #0ca1e3;
              border-color: #0ca1e3;
            }
          }
        }
      }
    }
  }
}

#block-bean-blog-teaser-text {
  padding: 0px 0 60px 15px;
  @media (min-width: @screen-sm-min){
    padding: 0px 0 60px 45px;
  }
  @media (min-width: @screen-lg-min){
    padding: 0px 0 30px 45px;
  }
  .field-name-field-text {
    h2 {
      margin-top: 0;
    }
  }
  .field-name-field-link {
    margin-top: 30px;
    .field-item {
      margin-bottom: 10px;
      a {
        line-height: 40px;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        text-decoration:none;
        &:after {
          background: #242424;
          bottom: -5px;
          content: "";
          left: 0;
          height: 5px;
          width: 50%;
          position: absolute;
          right: 0;
          transition: 0.2s width;
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.node-reference.view-mode-full {
  .row:first-of-type {
    //border-bottom: 1px solid #d0d0d0;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .sharethis-buttons {
    margin-top: 30px;
  }
  .field-name-references-link {
    text-align: center;
    color: #d0d0d0;
    margin-top: 30px;
    a {
      color: #d0d0d0;
      text-decoration: none;
      display: inline-block;
      width: 35px;
      height: 35px;
      line-height: 35px;
      border: 1px solid #d0d0d0;
    }
  }
  .rightside {
    .field {
      margin-bottom: 30px;
    }
  }
}

.flippy {
  padding: 0 !important;
  > li {
    padding: 0 !important;
    > a {
      font-size: 0;
      color: #d0d0d0;
      text-decoration: none;
      line-height: 1;
      display:block;
      position:relative;
      top: -35px;
    }
    &.prev {
      .pull-left;
      a:before {
        content: "\2039";
        font-size: 25px;
        border: 1px solid #d0d0d0;
        display: block;
        width: 35px;
        height: 35px;
      }
    }
    &.next {
      .pull-right;
      a:before {
        content: "\203A";
        font-size: 25px;
        border: 1px solid #d0d0d0;
        display: block;
        width: 35px;
        height: 35px;
      }
    }
  }
}


.region-sidebar-second {
  padding-top: 100px;
}

.view-references.view-id-references.view-display-id-page {
  .view-header {
    margin-bottom: 60px;
    font-size: 31px;
  }
  .pager-load-more {
    a {
      line-height: 40px;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #c9c9c9;
      text-decoration: none;
      &:hover {
        background: none;
        color: @text-color;
        &:after {
          background: @text-color;
        }
      }
      &:after{
        background: #c9c9c9;
        bottom: -5px;
        content: "";
        left: 0;
        height: 5px;
        width: 100%;
        position: absolute;
        right: 0;
        //transition: 0.2s width;
      }
    }
  }
}

.fancy-link {
  line-height: 40px;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #242424;
  text-decoration:none;
  &:after {
    background: #242424;
    bottom: -5px;
    content: "";
    left: 0;
    height: 5px;
    width: 50%;
    position: absolute;
    right: 0;
    transition: 0.2s width;
  }
  &:hover, &focus {
    text-decoration: none;
    &:after {
      width: 100%;
    }
  }
}


h2 > u {
  position:relative;
  text-decoration: none;
  &:after {
    background: #1094e7;
    bottom: 10px;
    content: "";
    left: 0;
    height: 5px;
    width: 50%;
    position: absolute;
    right: 0;
    transition: 0.2s width;
  }	
}

/* Contact form */
.entity-entityform-type {
  h2 {
    .h4;
  }
}
.node-type-site-contact-page #contact-form-entityform-edit-form {
	max-width: 660px;
	margin-left: auto;
	margin-right: auto;
	.form-type-textfield {
    font-size: 13px;
		width: 100%;
		border-bottom: 2px solid #242424;
		.form-control {
      background-color: transparent;
			border: 0 none;
			box-shadow: none;
      font-style: italic;
      display: inline-block;
      width: 80%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
		}
		label {
      font-size: 13px;
      font-weight: bold;
			text-transform: uppercase;
      width: auto;
			&:after {
				content: ':';
				position: relative;
				left: 0px;
			}
		}
	}
	.form-type-textarea {
    font-size: 13px;
    textarea {
      border: 2px solid #242424;
    }
		.grippie {
			display: none;
		}
    .form-control {
      background-color: transparent;
      font-style: italic;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
		label {
      font-size: 13px;
      font-weight: bold;
			text-transform: uppercase;
			&:after {
				content: ':';
				position: relative;
				left: 0px;
			}
		}
	}
  .field-name-field-data-policy {
    > .form-item {
      > .control-label {
        .hidden;
      }
    }
    .control-label {
      line-height: 20px;
    }
    a {
      text-decoration: underline;
    }
  }
	.form-actions {
		text-align:right;
	}
}

#block-system-main {
  .node-site-contact-page.view-mode-full {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* Employees */

.view-poolemployees {
  .view-header {
    strong {
      color: #38bafd;
      text-transform: uppercase;
    }
    margin-bottom: 60px;
  }
  .node {
    margin-bottom: 30px;
    h5 {
      margin-bottom: 0;
    }
  }
}


/* Isotope */
.view.view-category-filter {
	margin-top: 30px;
	margin-bottom: 30px;
	ul.isotope-filters {
		@media (min-width: @screen-sm-min) {
			text-align:center;
		}
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			list-style: none;
			display: inline-block;
			margin-bottom: 30px;
			a {
				//text-transform: uppercase;
        text-decoration: none;
				padding: 0 5px;
				margin: 0 10px;
				&.active {
					border-bottom: 2px solid;
				}
			}
		}
	}
}


/* Modal form */

#formModal {
  .modal-header {
    border-bottom: 0;
    .close {
      color: #fff;
      opacity: 1;
      font-size: 40px;
    }
  }
  .modal-content {
    background-color: #2d92d9;
    text-align: left;
    color: #fff;
    .modal-body {
      padding: 40px;
    }
    .entityform {
      #edit-submit {
        .btn-lg;
        .btn-block;
        background-color: #3f9fe2;
        text-transform: uppercase;
        &:hover, &:focus {
          background-color: darken(@brand-primary, 10%);
          border-color: darken(@brand-primary, 10%);
        }
      }
    }
  }
}


#block-views-kundlogon-block, #block-views-kundlogon-block-1 {
  .block-title {
	text-align: center;	  
  }
  .view-kundlogon {
    .views-bootstrap-grid-plugin-style {
      > .row {
        @media (min-width: @screen-lg-min){
          display:flex;
          align-items: center;
          &:before, &:after { display: none }
        }
      }
    }
    .no-padding {
      padding: 0;
    }
    .node {
      .field-name-field-logo {
        padding-top: 30px;
        padding-bottom: 30px;
        img {
          margin: 0 auto;
        }
      }
      .field-name-field-link {
        margin-top: 30px;
        .field-item {
            margin-bottom: 10px;
            a {
              line-height: 40px;
              position: relative;
              font-size: 20px;
              font-weight: 600;
              color: #242424;
              text-decoration:none;
              &:after {
                background: #242424;
                bottom: -5px;
                content: "";
                left: 0;
                height: 5px;
                width: 50%;
                position: absolute;
                right: 0;
                transition: 0.2s width;
              }
              &:hover {
                &:after {
                  width: 100%;
                }
              }
            }
        }
      }
      .group-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin:0 auto;
        color: #fff;
        text-decoration: none;
        background-color: #000000;
        opacity: 0;
        webkit-transition: opacity .5s;
          transition: opacity .5s;

        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        &:hover {
          opacity: 0.82;
        }
        .field-name-title-field {
          color: #fff;
          text-decoration: none;
          text-align:center;
        }
      }
    }
  }
}

#sliding-popup {
  border-top: 1px solid #f0f0f0;
  .popup-content {
    #popup-buttons button {
      .btn;
      .btn-default;
      background-color: #fff;
      padding: 8px 25px;
    }
    .popup-text {
      a {
        text-decoration: underline;
      }
    }
  }
}

.field-name-field-image .img-responsive {
	width: 100%;	
}

.col-reverse {
  .col-sm-6 {
	.make-sm-column-pull(6);
  }
  .col-sm-6:first-child {
	.make-sm-column-push(6);
	display:flex;
	justify-content: flex-end;
  }
  .field-name-field-bundle-image {
	.img-responsive {
	  margin-left: auto;
	}
  }
}

.paragraphs-item-full-width-banner {
  position: relative;
  
  .background-img {
	min-height: 600px !important;
  }
	
  .group-caption {
	.container();
	position: absolute;
	top: 50%;
	left: 0;
	right:0;
	margin: 0 auto;
	transform: translateY(-50%);
	color: #fff;
	@media (min-width: @screen-sm-min){
		display: flex;
		align-items: center;
	}  
	.field-name-field-p-title {
	  .h3;
      margin-right: 15px;
	}
  }
}

.node-permanent-page {
	&.view-mode-full {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	
	h1, h2 {
		display: table;
		margin-bottom: 30px;
		text-decoration: underline;
		text-underline-offset: 15px;
		-webkit-text-decoration-color: #1094E7; /* Safari */  
  		text-decoration-color: #1094E7;
		line-height: 1.4;
		/* word-break: break-word; */
		&.rtecenter {
			margin-left:  auto;
			margin-right: auto;
		}
	}	
}
